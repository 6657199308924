import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import CountryCodeDropdown from './partials/CountryCodeDropdown';
import { sendemail } from '@store/auth/authActions';
import BreadCrumb from '@components/partials/BreadCrumbs';
import MovieShow from './partials/movieShow.jsx';

import ShowDetailsPopup from './partials/popups/showDetailsPopup.jsx';
import { Helmet } from 'react-helmet';
import SeatTypePopup from './partials/popups/SeatTypePopup.jsx';
import { motion } from 'framer-motion';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import { fetchAllCinemas, selectedCinema } from '@store/cinemas/cinemasActions';

import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from 'react-bootstrap';
import Slider from 'react-slick';

function Feedback({ t }) {
  const [allCinemasLoaded, setAllCinemasLoaded] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [email, setEmail] = useState(``);
  const [mobile, setMobile] = useState(``);
  const [cinemaname, setCinemaname] = useState(``);
  const [countryId, setCountryId] = useState(null);
  const [suggestions, setSuggestions] = useState(``);
  const [countryMobCode, setCountryMobCode] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const { pathname } = useLocation();
  const [mobileError, setMobileError] = useState('');
  const cinemas = useSelector((state) => state.cinemas);
  // console.log('cinemas:', cinemas);
  const { cinemasLoading, allCinemas } = cinemas;
  // console.log(mobile, 'mobile');
  // console.log(cinemaname, 'cinemaname');
  // console.log(countryMobCode, 'countryMobCode');
  // console.log(countryId, 'countryId');
  const selectedCinema = allCinemas[0];
  // console.log('selectedCinema:', selectedCinema);
  useEffect(() => {
    // dispatch(fetchAllCinemas());
    dispatch(fetchAllCinemas())
      .then((response) => {
        setAllCinemasLoaded(true);
      })
      .catch((error) => {
        console.error('Error fetching cinemas:', error);
      });
  }, [dispatch]);
  const isBookEvent = pathname === '/book-event';

  let globalOptions = useSelector((state) => state.movies.globalOptions);
  const stc_id = 3;
  // globalOptions.find(
  //   (g) => g.go_key === `MYCINEMA_FEEDBACK_SUPPORT_TICKET_CATEGORY_ID`,
  // )?.go_value ?? 0;
  console.log({ stc_id });

  const auth = useSelector((state) => state.auth);
  const cinema = useSelector((state) => state.movies.selectedCinema);
  const { loggedInUser } = auth;
  let customer_id = loggedInUser?.cust_id || null;
  let contact_no = loggedInUser?.cust_mobile || null;
  let cinema_id = cinema?.cinema_id || null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(function fieldMarginReducer() {
    const fields = document.querySelectorAll('.fields');
    fields.forEach(function (field) {
      console.log(field);
      if (
        field.nextElementSibling &&
        field.nextElementSibling.classList.contains('error')
      ) {
        field.style.marginBottom = '3px';
      }
    });
  });

  const validateMobile = () => {
    let isValid = true;
    if (!mobile || !countryMobCode) {
      setMobileError('Please enter Valid Mobile Number and Country Code.');
      isValid = false;
    }
    // else if (countryMobCode === '971' && !/^0\d{9}$/.test(mobile)) {
    //   setMobileError('Please enter a valid Mobile Number.');
    //   isValid = false;
    // }
    else {
      setMobileError('');
    }
    return isValid;
  };

  const submitForm = (e) => {
    e.preventDefault();
    setSubmitted(true);
    const isMobileValid = validateMobile();
    if (email && suggestions && cinemaname && isMobileValid && countryMobCode) {
      setValidForm(true);
    }
  };
  const headOffice = {
    cinema_id: 0,
    cinema_name: 'Phars Film, 2705, ETA Al Manara Tower, Business Bay, Dubai',
    city_name: 'HEAD OFFICE',
    cinema_phone_number: '+971 4 7702121',
    cinema_email: 'info@starcinemas.com',
  };
  const updatedCinemas = [headOffice, ...allCinemas];
  console.log('updatedCinemas:', updatedCinemas);

  const cinemasByCity = updatedCinemas.reduce((acc, cinema) => {
    const cityName = cinema.city_name;

    if (!acc[cityName]) {
      acc[cityName] = [];
    }

    acc[cityName].push(cinema);

    return acc;
  }, {});

  const priorityCities = [
    'Fujairah',
    'Umm Al Quwain',
    'Dubai',
    'Ajman',
    'Ras Al Khaimah',
    'HEAD OFFICE',
  ];

  const sortedCities = Object.keys(cinemasByCity).sort((a, b) => {
    const priorityA = priorityCities.indexOf(a);
    const priorityB = priorityCities.indexOf(b);

    return priorityB - priorityA;
  });
  const groupedCities = [];
  for (let i = 0; i < sortedCities.length; i += 3) {
    groupedCities.push(sortedCities.slice(i, i + 3));
  }

  useEffect(() => {
    if (validForm) {
      dispatch(
        sendemail({
          // name: 'User from Mycinema',
          email,
          contact_no: mobile,
          countryMobCode: countryMobCode,
          message: suggestions,
          cinemaname,
          customer_id,
          cinema_id,
          stc_id,
        }),
      ).then(() => {
        setStatus(true);
      });
    }
  }, [validForm]);

  useEffect(() => {
    if (status) {
      setEmail('');
      setMobile('');
      setSuggestions('');
      setCinemaname('');
      setCountryId('');
      setCountryMobCode(null);
    }
  }, [status]);

  return (
    <>
      <Helmet>
        <title>Contact Us- Get in Touch with Star Cinemas</title>
        <meta name="description" content={`Explore this page.`} />
        <meta
          name="keywords"
          content="contact Star Cinemas, cinema customer support UAE, Star Cinemas contact details"
        />
      </Helmet>
      <div className="contact_breadcrumb">
        <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('Contact Us')} />
      </div>
      {allCinemasLoaded ? (
        <div
          className="header-space container-fluid container-xl feedback-container"
          style={{ backgroundColor: 'var(--dark-9)' }}
        >
          <div className="row main_contact_wrapper justify-content-start">
            {sortedCities.map((cityName) => (
              <div
                key={cityName}
                className="feedback col-xl-4 col-md-6 col-sm-12 mx-0 custom_padding_mobile"
              >
                <p className="sub-title mx-auto text-left text-primary">
                  {cityName}
                </p>
                {cinemasByCity[cityName].map((cinema) => (
                  <div key={cinema.cinema_id} className="mb-3">
                    <p className="mb-2">{cinema.cinema_name}</p>
                    <p>
                      Phone:+{cinema.cine_mobile_code} {cinema.cinema_phone_number}
                      <br />
                      Email: {cinema.cinema_email}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '200px' }}
        >
          <div>Loading...</div>
        </div>
      )}
      <div className="header-space container-fluid container-xl feedback-container">
        {/* <motion.p
          className="title"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          {pathname === "/book-event" ? "Booking" : "Contact"}
        </motion.p> */}
        {/* <div className='main_wrapper_contact_us'>

</div> */}

        <motion.div
          className="feedback"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.75 }}
        >
          <p className="sub-title mx-auto">
            {pathname === '/book-event' ? t('Book an Event') : t('Contact Us')}
          </p>

          {status && (
            <div className="email-success-message">
              {t('Message sent successfully')}{' '}
            </div>
          )}

          <form onSubmit={submitForm}>
            <div className="row">
              <div className="col-12 col-md-12">
                <input
                  className="fields"
                  type="email"
                  placeholder={
                    pathname === '/book-event'
                      ? t('Please provide event details')
                      : t('Enter Email Address')
                  }
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <p className="error">
                  {submitted &&
                    !status &&
                    email === '' &&
                    t('Please enter your email')}
                </p>
              </div>
              <div className="d-block  col-xl-6 col-md-12">
                <div className="d-flex align-items-baseline contact_form_country_dropdown p-0">
                  <CountryCodeDropdown
                    className="country_code_drop mr-2"
                    onSelectCountry={({ country_id, country_mob_code }) => {
                      setCountryId(country_id);
                      setCountryMobCode(country_mob_code);
                    }}
                  />
                  <input
                    className="fields"
                    type="number"
                    placeholder={t('Enter Your Mobile Number')}
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    style={{ marginBottom: '3px' }}
                  />
                </div>
                <p className="error">
                  {/* {submitted &&
                    !status &&
                    mobile === '' &&
                    t('Please Enter your Mobile Number')} */}
                  {submitted && !status && mobileError}
                </p>
              </div>
              <div className="col-xl-6 col-md-12">
                <select
                  className={'fields'}
                  onChange={(e) => {
                    setCinemaname(e?.target?.value);
                  }}
                >
                  <option
                    value=""
                    disabled
                    selected
                    hidden
                    style={{ color: 'var(--light-4)' }}
                  >
                    Select Cinema
                  </option>
                  {allCinemas?.map((cinemaData, index) => {
                    return (
                      <option value={cinemaData?.cinema_name}>
                        {cinemaData?.cinema_name}
                      </option>
                    );
                  })}
                </select>
                <p className="error">
                  {submitted &&
                    !status &&
                    cinemaname === '' &&
                    t('Please Select Cinema Name')}
                </p>
              </div>
              {/* <div className="col-12 col-md-6">
                <div className="fields fb">
                  <Dropdown className="">
                    <Dropdown.Toggle
                      className="dropdownClassic"
                      id="dropdown-basic"
                    >
                      Select Cinema
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="right" className="dropdownItems">
                      <Dropdown.Item href="#/action-1" className="selected">
                        Ticketing
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="">
                        Food &amp; Baverages
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Customer Experience
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="fields fb">
                  <Dropdown className="fb">
                    <Dropdown.Toggle
                      className="dropdownClassic"
                      id="dropdown-basic"
                    >
                      Subject
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="right" className="dropdownItems">
                      <Dropdown.Item href="#/action-1" className="selected">
                        Ticketing
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="">
                        Food &amp; Baverages
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Customer Experience
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <input
                  className="fields"
                  type="text"
                  placeholder="Showtime and Ticket"
                />
              </div> */}
              <div className="col-12">
                <textarea
                  className="fields"
                  rows="8"
                  placeholder={t('type-suggestions')}
                  value={suggestions}
                  onChange={(e) => setSuggestions(e.target.value)}
                />

                <p className="error">
                  {submitted &&
                    !status &&
                    suggestions === '' &&
                    t('Please enter suggestions')}
                </p>
              </div>
              <div className="col-12 col-sm-5 mx-auto">
                <button className="btn-main mx-auto" type="submit">
                  {t('Submit')}
                </button>
              </div>
            </div>
          </form>
        </motion.div>
      </div>
    </>
  );
}

export default withNamespaces()(Feedback);
